import { AfterViewInit, Component } from '@angular/core';
import { HelpersService } from './services/helpers/helpers.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalsService } from './state/globals/globals.service';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { NavigationService } from './services/navigation/navigation.service';
import { LanguageService } from './state/language/language.service';
import { Lang } from './models/language.model';
import { environment } from '../environments/environment';


@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  public constructor(
    private ims: IdleMonitorService,
    private helpers: HelpersService,
    private router: Router,
    private navigation: NavigationService,
    private globals: GlobalsService, // Required to reference here to call internal constructor on app start
    private languageService: LanguageService,
  ) {

    this.init();
  }

  ngAfterViewInit(): void {
    this.hideSplashScreen()
    this.preloadModules()
    this.fireManualMyAppReadyEvent()
    
    this.forceDefaultLanguage()
  }
  
  /**
   * Force default language to be set
   */
  private forceDefaultLanguage() {
    // TEMP fix 2021-11-15
    this.languageService.current = environment.defaultLanguage as Lang
  }

  /**
   * Init component
   */
  private async init(): Promise<void> {
    this.listenRouterEvents()
  }

  /**
   * Default hide splash screen after constant time, but every page should manually hide splash screen before.
   * This is just a protection to avoid application stacked in the state with the splash screen if some component/page do not hide it manually.
   */
  private hideSplashScreen(): void {
    setTimeout(() => {
      this.helpers.hideSplashScreen();
    }, 1000);
  }

  /**
   * Some modules are set to be Lazy at app-routing.module.ts.
   * This is nice from faster loading of Home screen but it has negative UX at first Router navigation to these modules.
   * Best combination is to keep then Lazy at Routing module but to load them Async in background when Home page is available.
   */
  private async preloadModules(): Promise<void> {
    setTimeout(() => {
      import('./pages/blog-page/blog.module').then(m => m.BlogModule)
    }, 0)
  }

  /**
   * Scully should get an manual trigger to know when the app is ready to be used with full content.
   */
  private fireManualMyAppReadyEvent() {
    // Wait 5 seconds then fire the event to trigger that all content is loaded
    // it is much easier to wait 5 seconds per page than implementing complex logic to know when all content is loaded
    // TODO: refactor this to use a service to fire the event when website will have 100+ pages, for low number of pages
    // this simple implementation is good-enough
    setTimeout(() => {
      console.log('AppComponent.fireManualMyAppReadyEvent');
      // this.ims.fireManualMyAppReadyEvent();
    }, 5000)
  }
  
  /**
   * Listen for route change and scroll to the top of the page
   */
  private listenRouterEvents() {

    // Listen for URL changes at router
    this.router.events
      .pipe(untilDestroyed(this))
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Scroll to top on route change
        setTimeout(() => {
            window.scroll(0,0)
        }, 0);
    })
  }
}
