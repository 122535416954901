import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GlobalTranslation } from '../../models/globals.model';
import { GlobalsService } from '../../state/globals/globals.service';

@UntilDestroy()
@Component({
  selector: 'sss-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public navbar = {
    titleImage: 'https://ik.imagekit.io/scanshop/scanshop-landing-elements/scanshop-logo-blue-with-title.svg',
    title: `ScanShop`,
    titleLink: '/',
    activeLink: '/',
    mainImage: 'https://ik.imagekit.io/scanshop/scanshop-landing-elements/scanshop-logo-blue-with-title-white.svg',
    copyrightText: 'All rights reserved by ITalk Ltd.',
  }

  constructor(
    private globalsService: GlobalsService,
  ) {

  }

  ngOnInit(): void {

    this.globalsService.currentTranslation$.pipe(untilDestroyed(this)).subscribe((globalTranslation: GlobalTranslation) => {
      console.log('layout.globalTranslation', globalTranslation);
      this.navbar.copyrightText = globalTranslation?.copyright?.text;
    })
  }

}
