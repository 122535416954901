<div class="navbar-wrapper" >

  <section>
    <nav class="relative px-6 py-6 pr-2 flex justify-between items-center bg-white">
      <a *ngIf="titleImage" [routerLink]="[titleLink]">
        <img src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/scanshop-logo-blue-with-title.svg" alt="">
      </a>

      <div class="lg:hidden z-40">
        <button class="navbar-burger flex items-center" (click)="isOpenMenu = !isOpenMenu">
          <img src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/navbar-icons/hamburger-menu-icon-ss-blue.svg" alt="">
        </button>
      </div>

      <ul class="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:items-center lg:w-auto lg:space-x-6">

        <ng-container *ngFor="let item of items?.slice(0, -1)" >
          <li>
            <a [routerLink]="[item.link]"
              [ngClass]="item.isActive || activeLink === item.link ? 'text-gray-700 text-right' : 'text-gray-400'"
              class="text-xl font-semibold text-gray-400 hover:text-gray-700">{{ item.title }}</a>
          </li>
        </ng-container>

      </ul>

      <div class="hidden lg:flex items-center">

        <ng-container *ngFor="let item of items?.slice(-1)" >
          <a [routerLink]="[item.link]" class="hidden lg:inline-block py-3 px-6 text-lg text-white font-bold rounded-full bg-primary mr-2" href="#">{{ item.title }}</a>
        </ng-container>

        <div *ngIf="isMultiLanguage">
          <ng-container *ngFor="let language of availableLanguages" >
            <a [ngClass]="language.key === currentLanguageKey ? 'text-blue-500 font-bold' : 'text-gray-400'"
               class="hidden lg:block py-1 m-0 px-2 font-semibold uppercase cursor-pointer h-8"
               [ngStyle]="{ color: language.key === currentLanguageKey ? '#00A2FF' : '' }"
               (click)="setLanguage(language.key)">{{ language.key }}</a>
          </ng-container>
        </div>

      </div>
    </nav>
    <div class="hidden navbar-menu relative z-30" [class.hidden]="!isOpenMenu">
      <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" (click)="isOpenMenu = false"></div>
      <nav class="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
        <div class="flex items-center mb-8">
        <a *ngIf="titleImage" [routerLink]="[titleLink]">
          <img src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/scanshop-logo-blue-with-title.svg" alt="">
        </a>
        </div>
        <div>
          <ul>
            <ng-container *ngFor="let item of items?.slice(0, -1)" >
              <li class="mb-1">
                <a [routerLink]="[item.link]"
                  [ngClass]="item.isActive || activeLink === item.link ? 'text-gray-700' : ''"
                  class="block p-4 text-base font-semibold text-gray-400 hover-bg-primary hover:text-white rounded">{{ item.title }}</a>
              </li>
            </ng-container>

            <ng-container *ngFor="let item of items?.slice(-1)" >
              <a [routerLink]="[item.link]" class="hidden lg:inline-block py-3 px-6 text-lg text-white font-bold rounded-full bg-primary mr-2" >{{ item.title }}</a>
            </ng-container>

          </ul>
        </div>
        <div class="mt-auto">

          <ng-container *ngFor="let item of items?.slice(-1)" >
            <div class="pt-6"><a [routerLink]="[item.link]" class="block px-6 py-3 mb-2 leading-loose text-sm text-center text-white font-bold rounded-full bg-primary" >{{ item.title }}</a></div>
          </ng-container>

          <div class="flex justify-between items-center -m-2 my-4" *ngIf="isMultiLanguage" >
            <ng-container *ngFor="let language of availableLanguages" >
              <a [ngClass]="language.key === currentLanguageKey ? 'text-white bg-primary-red hover:bg-primary-red shadow' : 'text-gray-400 hover-text-primary-red bg-gray-200'"
                 class="inline-block w-full px-2 py-2 mt-0 mx-2 font-semibold uppercase cursor-pointer rounded-full text-center leading-none text-sm"
                 (click)="setLanguage(language.key)">{{ language.title }}</a>
            </ng-container>
          </div>

          <p class="my-4 text-xs text-center text-gray-400">
            <span [innerHTML]="copyrightText"></span>
          </p>
        </div>
      </nav>
    </div>
  </section>
</div>
