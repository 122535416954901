import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { AboutPage } from '../../models/about.page.model';
import { PagesContentService } from '../../services/pages/pages-content.service';
import { LanguageQuery } from '../../state/language/language.query';

@UntilDestroy()
@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

  constructor(
    private ims: IdleMonitorService,
    private content: PagesContentService,
    private languageQuery: LanguageQuery,
  ) { }

  private about: AboutPage;

  public status: string;
  public title: string;
  public markdownContent: string;
  // public markdownContentNumbers: string;
  // public markdownContentArea: string;

  private isMarkdownContentReadyCounter = 0;

  public onReadyMarkdownContent(): void {
    console.log('about.onReadyMarkdownContent');
    if (this.about) {
      this.isMarkdownContentReadyCounter += 1;
      // Fire that app is ready when all markdown fields are rendered
      if (this.isMarkdownContentReadyCounter >= 1) {
        console.log('about.fireManualMyAppReadyEvent');
        // this.ims.fireManualMyAppReadyEvent();
      }
    }
  }

  public async ngOnInit(): Promise<void> {

    this.languageQuery.select().pipe(untilDestroyed(this)).subscribe(async language => {

      const about = await this.content.getAbout();
      this.about = about;

      this.status = about.status;
      this.title = about.title;
      this.markdownContent = about.content;
      // this.markdownContentNumbers = about.numbers;
      // this.markdownContentArea = about.area;

      console.log('about', about);
    })
  }

}
