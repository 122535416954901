<div class="about-page-wrapper page-wrapper max-w-none prose lg:prose-lg" >

  <cms-content-status [status]="status"></cms-content-status>

  <div class="container" >
      
      <div class="-mt-10 -mb-4 border-b border-gray-200">
        <h2 class="lg:text-center">{{ title }}</h2>
      </div>
    
      <div class="flex flex-wrap overflow-hidden">
    
        <div class="flex flex-col items-center justify-center w-full overflow-hidden">
          <div class="content prose prose-blue lg:prose-lg mt-5" *ngIf="markdownContent" >
    
            <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>
    
            <!-- <div class="flex flex-wrap overflow-hidden">
    
              <div class="w-full overflow-hidden md:w-1/2">
                <markdown [data]="markdownContentArea" (ready)="onReadyMarkdownContent()"></markdown>
              </div>
    
              <div class="w-full overflow-hidden md:w-1/2">
                <markdown [data]="markdownContentNumbers" (ready)="onReadyMarkdownContent()"></markdown>
              </div>
    
            </div> -->
    
          </div>
        </div>
    
      </div>
  </div>
  
</div>

<app-project-partners></app-project-partners>

