import { NgModule } from '@angular/core'
import { Routes, RouterModule, UrlSegment } from '@angular/router'
import { HomePageComponent } from './pages/home-page/home-page.component'
import { AboutPageComponent } from './pages/about-page/about-page.component'
import { ContactPageComponent } from './pages/contact-page/contact-page.component'
import { EmptyPageComponent } from './pages/empty-page/empty-page.component'
import { Lang } from './models/language.model'
import { SimplePageComponent } from './pages/simple-page/simple-page.component'
import { AboutAppComponent } from './ui/about-app/about-app.component'

export const COMPLEMENT_ROUTES_DEFINITION = {
  en: {
    'empty':      { hr: 'prazno' },
    'home':       { hr: 'naslovnica' },
    'about':      { hr: 'o-nama' },
    'blog':       { hr: 'blog.hr' },
    'contact':    { hr: 'kontakt' },
  },

  hr: {
    'prazno':     { en: 'empty' },
    '':           { en: 'home' },
    'naslovnica': { en: 'home' },
    'o-nama':     { en: 'about' },
    'blog.hr':    { en: 'blog' },
    'kontakt':    { en: 'contact' },
  }
}

// NOTE(stepanic): when using `matcher` without `path` Scully is not able to detect these routes,
// it should be manually added to `scully.sss.config.ts` to `extraRoutes`
const routes: Routes = [

  {
    path: 'blog',
    loadChildren: () => import('./pages/blog-page/blog.module').then(m => m.BlogModule),
    data: {
    }
  },

  {
    path: 'blog.hr',
    loadChildren: () => import('./pages/blog-page/blog.module').then(m => m.BlogModule),
    data: {
    }
  },

  // {
  //   matcher: (url: UrlSegment[]) => {

  //     if (url.length === 1) {
  //       const first = url[0]
  //       if (first.path === 'empty' || first.path === 'prazno') {
  //         return { consumed: url }
  //       }
  //     }

  //     return null
  //   },
  //   component: EmptyPageComponent,
  // },

  {
    path: '',
    component: HomePageComponent
  },
  {
    path: 'home',
    component: HomePageComponent
  },
  {
    path: 'naslovnica',
    component: HomePageComponent
  },

  // NOTE(stepanic): nice transitioning betwen languages with keeping user ot the same component without rerendering it is not possible
  // with Scully because when using `matcher` it is not possible to manual idle check
  // {
  //   matcher: (url: UrlSegment[]) => {

  //     // path = ''
  //     if (url.length === 0) {
  //       return { consumed: url }
  //     }

  //     // path = 'naslovnica'
  //     if (url.length === 1) {
  //       const first = url[0]
  //       if (first.path === 'naslovnica') {
  //         return { consumed: url }
  //       }
  //     }

  //     return null
  //   },
  //   component: HomePageComponent,
  // },

  {
    path: 'about',
    component: AboutPageComponent
  },
  {
    path: 'o-nama',
    component: AboutPageComponent
  },

  // {
  //   matcher: (url: UrlSegment[]) => {

  //     if (url.length === 1) {
  //       const first = url[0]
  //       if (first.path === 'about' || first.path === 'o-nama') {
  //         return { consumed: url }
  //       }
  //     }

  //     return null
  //   },
  //   component: AboutPageComponent,
  // },

  {
    path: 'contact',
    component: ContactPageComponent
  },
  {
    path: 'kontakt',
    component: ContactPageComponent
  },

  // {
  //   matcher: (url: UrlSegment[]) => {

  //     if (url.length === 1) {
  //       const first = url[0]
  //       if (first.path === 'contact' || first.path === 'kontakt') {
  //         return { consumed: url }
  //       }
  //     }

  //     return null
  //   },
  //   component: ContactPageComponent
  // },
  {
    path: 'o-aplikaciji',
    component: AboutAppComponent
  },
  {
      path: ':slug',
      component: SimplePageComponent,
  },
  // It should never be reachable, because previous rule should match 
  {
    path: '**',
    redirectTo: `'/'`
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
