

<div class="contact-page-wrapper page-wrapper max-w-none prose prose-blue lg:prose-lg">

  <cms-content-status [status]="status"></cms-content-status>

  <div class="container" >
      
    <div class="-mt-10">
        <h2 class="lg:text-center">{{ title }}</h2>
    </div>
        
  </div>
  
  <div class="flex w-screen border-b border-t border-gray-200" >
    <div style="background-image: url('https://ik.imagekit.io/scanshop/scanshop-landing-elements/contact-page/contact-header-left-1px.png?updatedAt=1636939937524'); background-repeat: repeat-x;background-size: contain; flex: 1">&nbsp;</div>
    <div>
        <img style="width: 721px; margin: 0" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/contact-page/contact-header.jpg?updatedAt=1636939328077" >
    </div>
    <div style="background-image: url('https://ik.imagekit.io/scanshop/scanshop-landing-elements/contact-page/contact-header-right-1px.png?updatedAt=1636939937496'); background-repeat: repeat-x; background-size: contain; flex: 1">&nbsp;</div>
  </div>
  
  <div class="container" >
    
    <div class="flex flex-wrap overflow-hidden">
    
      <div class="flex flex-col items-center justify-center w-full overflow-hidden ">
        <div class="content prose prose-red lg:prose-lg w-full mt-5" >    
    
          <div class="flex flex-wrap overflow-hidden">
    
            <div class="w-full overflow-hidden">
              <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>
            </div>
    
          </div>
    
        </div>
      </div>
    </div>
    
  </div>
    
</div>

<!-- <app-project-partners></app-project-partners> -->

