import { Component, Input, OnInit } from '@angular/core';
import { isScullyGenerated } from '@scullyio/ng-lib';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'cms-content-status',
  template: `
  <div *ngIf="!isProduction && !isStatic && ['draft', 'review', 'publish'].includes(status)" class="fixed bottom-1 right-0 z-50" >
    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-gray-600 last:mr-0 mr-1"
          [ngClass]="{ 'bg-red-600': status === 'review', 'bg-green-600': status === 'publish' }">
      {{ status }}
    </span>
  </div>
  `,
  styles: [
  ]
})
export class ContentStatusComponent implements OnInit {

  public isStatic = isScullyGenerated();
  public isProduction = environment.production;

  @Input()
  status: string;

  constructor() { }

  ngOnInit(): void {
  }

}
