<div class="project-partners-wrapper">

    <section class="bg-gray-100">
        <div class="py-8 lg:py-20">
          <div class="container mx-auto px-4">
            <div class="mb-8 sm:mb-4 lg:mb-16 mx-auto max-w-2xl text-center">
              <div class="font-bold intro-heading text-lg lg:text-2xl mb-4">
                <ng-container *ngIf="language?.key === 'hr'">
                  EU projekt
                </ng-container>
                <ng-container *ngIf="language?.key === 'en'">
                  EU project
                </ng-container>
              </div>
              <h2 class="text-3xl lg:text-4xl font-bold">
                <ng-container *ngIf="language?.key === 'hr'">
                  <!-- Partneri na projektu -->
                  Ulaganje u završnu prilagodbu i lansiranje aplikacije ScanShop na tržište
                </ng-container>
                <ng-container *ngIf="language?.key === 'en'">
                  <!-- Partners of the Project -->
                  Investing in the final customization and launch of the ScanShop application
                </ng-container>
              </h2>
            </div>
            
            <div class="flex flex-wrap items-baseline lg:items-end justify-center">
              
              <div class="mb-4 w-full sm:w-1/2 md:w-1/4 px-4">
                <a href="https://strukturnifondovi.hr/hr/" target="_blank" >
                  <img *ngIf="language?.key === 'hr'" class="w-auto h-24 inline-block" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/eu-fonds/esif-logo/esif-logo-hr.svg?updatedAt=1636761142380&tr=h-192" alt="Europski strukturni i investicijski fondovi">
                </a>
                <a href="https://strukturnifondovi.hr/en/" target="_blank" >
                  <img *ngIf="language?.key === 'en'" class="w-auto h-24 inline-block" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/eu-fonds/esif-logo/esif-logo-en.svg?updatedAt=1636761142380&tr=h-192" alt="European Structural and Investment Funds">
                </a>
              </div>
              
              <div class="mb-4 w-full sm:w-1/2 md:w-1/4 px-4">
                <a href="https://strukturnifondovi.hr/hr/" target="_blank" >
                  <img *ngIf="language?.key === 'hr'" class="w-auto max-h-24 inline-block lg:hidden" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/eu-fonds/cro-flag/cro-flag-hr.svg?updatedAt=1636761142380&tr=h-192" alt="Republika Hratska">
                  <img *ngIf="language?.key === 'hr'" class="w-auto max-h-24 hidden lg:inline-block" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/eu-fonds/cro-flag/cro-flag.svg?updatedAt=1636761142380&tr=h-192" alt="Republika Hratska">
                </a>
                <a href="https://strukturnifondovi.hr/en/" target="_blank" >
                  <img *ngIf="language?.key === 'en'" class="w-auto max-h-24 inline-block lg:hidden" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/eu-fonds/cro-flag/cro-flag-en.svg?updatedAt=1636761142380&tr=h-192" alt="Republic of Croatia">
                  <img *ngIf="language?.key === 'en'" class="w-auto max-h-24 hidden lg:inline-block" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/eu-fonds/cro-flag/cro-flag.svg?updatedAt=1636761142380&tr=h-192" alt="Republic of Croatia">
                </a>
              </div>
              
              <div class="mb-4 w-full sm:w-1/2 md:w-1/4 px-4">
                <a href="https://strukturnifondovi.hr/hr/" target="_blank" >
                  <img *ngIf="language?.key === 'hr'" class="w-auto max-h-24 inline-block" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/eu-fonds/op-pr-logo/op-pr-konk-i-koh-logo-hr.svg?updatedAt=1636761142380&tr=h-192" alt="Operativni program - Konkurentnost i kohezija">
                </a>
                <a href="https://strukturnifondovi.hr/en/" target="_blank" >
                  <img *ngIf="language?.key === 'en'" class="w-auto max-h-24 inline-block" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/eu-fonds/op-pr-logo/op-pr-konk-i-koh-logo-en.svg?updatedAt=1636761142380&tr=h-192" alt="Operational programme - Competitiveness and Cohesion">
                </a>
              </div>
              
              <div class="mb-4 w-full sm:w-1/2 md:w-1/4 px-4">
                <a href="https://strukturnifondovi.hr/hr/" target="_blank" >
                  <img *ngIf="language?.key === 'hr'" class="w-auto h-24 inline-block" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/eu-fonds/eu-flag/eu-horizontal-hr.svg?updatedAt=1636761142380&tr=h-192" alt="Europska Unija - Zajedno do fondova EU">
                </a>
                <a href="https://strukturnifondovi.hr/en/" target="_blank" >
                  <img *ngIf="language?.key === 'en'" class="w-auto h-24 inline-block" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/eu-fonds/eu-flag/eu-horizontal-en.svg?updatedAt=1636761142380&tr=h-192" alt="European Union - Together to EU Funds">
                </a>
              </div>
            </div>
            
            <div class="mt-0 lg:mt-8 mx-auto font-bold text-center">
              <h2 class="text-xl lg:text-2xl">
                <ng-container *ngIf="language?.key === 'hr'">
                  Projekt je sufinancirala Europska unija iz Kohezijskog fonda
                </ng-container>
                
                <ng-container *ngIf="language?.key === 'en'">
                  The project was co-financed by the European Union from the Cohesion Fund
                </ng-container>
              </h2>
            </div>
            
            <div class="read-more-wrapper mt-8">
              <button *ngIf="language?.key === 'en'" [routerLink]="['/blog', 'eu-project-2019-2021']">Read more</button>
              <button *ngIf="language?.key === 'hr'" [routerLink]="['/blog.hr', 'eu-projekt-2019-2021']">Pročitaj više</button>
            </div>
            
            <div class="mt-12">
                <ng-container *ngIf="language?.key === 'hr'">
                  Odricanje od odgovornosti: Sadržaj ove internetske stranice i razvijenih mobilnih aplikacija isključivo je odgovornost ITalk d.o.o. i ne odražava nužno gledište Europske unije.
                </ng-container>
                
                <ng-container *ngIf="language?.key === 'en'">
                  Disclaimer: The content of this website and developed mobile applications is the sole responsibility of ITalk Ltd. and does not necessarily reflect the view of the European Union.
                </ng-container>                
            </div>
          </div>
        </div>
    </section>
    
</div>
    