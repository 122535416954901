import { Injectable } from '@angular/core';
import { FLContent } from 'ng-flamelink';
import { from } from 'rxjs';
import { AboutPage } from '../../models/about.page.model';
import { BlogPage } from '../../models/blog.page.model';
import { ContactPage } from '../../models/contact.page.model';
import { HomePage } from '../../models/home.page.model';
import { Page } from '../../models/page.model';
import { SimplePage } from '../../models/simple.page.model';
import { LanguageQuery } from '../../state/language/language.query';
import { HelpersService } from '../helpers/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class PagesContentService {

  constructor(
    private content: FLContent,
    private helpers: HelpersService,
    private languageQuery: LanguageQuery
  ) { }
  
  /**
   * Fetch general simple page from Flamelink API by slug
   * @param slug is page identificator
   * @returns SimplePage data
   */
  public async getSimplePage(slug: string, additionalContentFields: string[] = []): Promise<SimplePage> {

    additionalContentFields = additionalContentFields.concat(['language', 'en', 'hr'])
    const simplePageDocument = this.content.get(this.getSimplePageQuery(slug));
    
    const page$ = this.helpers.safeUseScullyTransferState(`page_simple_${slug}`, from(simplePageDocument));

    return new Promise((resolve) => {
      const sub = page$.subscribe((pages) => {
        const page = pages?.pop() as SimplePage
        console.log('getSimplePage', slug, page);

        // Get current language
        const data: SimplePage = this.pageDataHandler(page, additionalContentFields) as SimplePage;
        
        // NOTE: take one value and unsubscribe
        setTimeout(() => {
          sub.unsubscribe();
        });

        resolve(data);
      });
    });
    
  }

  // Custom pages getter 
  // when page has it's own Flamelink schema that should have custom getter
  public async getHome(): Promise<HomePage> {

    const home = (await this.getCustomSinglePage('home', ['hero'])) as HomePage;
    return home;
  }

  public async getAbout(): Promise<AboutPage> {

    const about = (await this.getCustomSinglePage('about', ['numbers', 'area'])) as AboutPage;
    return about;
  }

  public async getBlog(): Promise<BlogPage> {

    const blog = (await this.getCustomSinglePage('blog')) as BlogPage;
    return blog;
  }

  public async getContact(): Promise<ContactPage> {

    const contact = (await this.getCustomSinglePage('contact', ['links'])) as ContactPage;
    return contact;
  }
  
  /**
   * Get page data by key
   * @param key is page identificator
   */
   private async getCustomSinglePage(key: string, additionalContentFields: string[] = []): Promise<Page> {
    const page$ = this.helpers.safeUseScullyTransferState(`page_${key}`, from(this.getPageDocument(key)));

    return new Promise((resolve) => {
      const sub = page$.subscribe((page: Page) => {
        console.log('getPage', key, page);

        // Get current language
        const data: Page = this.pageDataHandler(page, additionalContentFields);
        
        // NOTE: take one value and unsubscribe
        setTimeout(() => {
          sub.unsubscribe();
        });

        resolve(data);
      });
    });
  }

  private pageDataHandler(page: Page, additionalContentFields: string[]) {
      const language = this.languageQuery.getValue();

      if (!page) {
        return null;  
      }
      
      // NOTE: without filtering Flamelink document
      // BAD: images are not proxied
      // GOOD: other properties can be access from the outside
      // return page;
      // Try to get specific translation of some content
      const pageTranslation: Page = page[language?.key];

      // Ifr translation is not available fallback to default (this is backward compatible behaviour for one language site)
      const title = pageTranslation?.title || page.title;
      const content = pageTranslation?.content || page.content;

      // NOTE: with filtering & transforming data from Flamelink document
      const data: Page = {
          status: (page as any)?._fl_meta_?.status,
          title,
          content: this.helpers.proxyImages(content) as string,
      };

      // about page has fields: numbers and area
      additionalContentFields.forEach(contentField => {
          data[contentField] = this.helpers.proxyImages(pageTranslation && pageTranslation[contentField] || page[contentField]);
      });
      console.log('pages-content.service.additionalContentFields', additionalContentFields);

      setTimeout(() => {
          this.helpers.hideSplashScreen();
      });
      return data;
  }

  /**
   * Get page document from Flamelink
   * @param key is schema key
   */
  private getPageDocument<T>(key: string): Promise<T> {
    return this.content.get({
      schemaKey: key
    });
  }
  
  /**
   * Single and simple page 
   * @param slug
   * @returns
   */
   private getSimplePageQuery(slug: string): any {
    return {
      schemaKey: 'simplePage',
      filters: [
        // Always fallback to default slug if looking for specific language is not provided
        [ 'slug', '==', slug ]
      ],
      populate: [
        {
          field: 'en',
          subFields: [
            {
              field: 'translation'
            }
          ]
        },
        {
          field: 'hr',
          subFields: [
            {
              field: 'translation'
            }
          ]
        }
      ]
    };
  }

}
