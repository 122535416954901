<div class="about-app-header-wrapper" >
        <section>
          <div class="py-20 bg-primary">
            <div class="container mx-auto px-4">
              <div class="mb-16 mx-auto max-w-lg text-center text-white">
                <p class="lg:mb-8 mb-4 font-bold">Digitalni asistent kupovine</p>
                <h2 class="lg:mb-8 mb-4 text-2xl lg:text-4xl font-bold">Brza i jednostavna kupovina uz ScanShop!</h2>
                <p>Preuzmite iOS ili Android aplikaciju i doživite kupovinu na bolji način!</p>
              </div>
              <div class="flex flex-wrap -mx-4">
                <div class="w-1/2 mx-auto max-w-lg text-center"><img class="w-full" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/home-page/mobile-phone-screens.svg" alt=""></div>
              </div>
            </div>
          </div>
        </section>
</div>
