<div class="simple-page-wrapper page-wrapper max-w-none prose lg:prose-lg" >
    
    <section class="preloader-wrapper p-4 text-center font-semibold text-2xl" *ngIf="simplePage === undefined || simplePage === null || !isTranslationFound">
    
      <ng-container *ngIf="simplePage === undefined">
        <div>
          <ng-container *ngIf="currentLanguageKey === 'en'" >
            Loading page in progress...
          </ng-container>
          <ng-container *ngIf="currentLanguageKey === 'hr'" >
            Učitavanje stranice u tijeku...
          </ng-container>
        </div>
      </ng-container>
    
      <ng-container *ngIf="simplePage === null">
        <div>
          <ng-container *ngIf="currentLanguageKey === 'en'" >
            Sorry, but the page for requested slug is not found! :(
          </ng-container>
          <ng-container *ngIf="currentLanguageKey === 'hr'" >
            Nažalost, za traženi slug stranica nije pronađena! :(
          </ng-container>
        </div>
      </ng-container>
    
      <ng-container *ngIf="!isTranslationFound">
        <div>
          <ng-container *ngIf="currentLanguageKey === 'en'" >
            Sorry, but the translation for requested page is not found! :(
          </ng-container>
          <ng-container *ngIf="currentLanguageKey === 'hr'" >
            Nažalost, prijevod za traženu stranicu ne postoji! :(
          </ng-container>
        </div>
      </ng-container>
      
    </section>

    <cms-content-status [status]="status"></cms-content-status>
  
    <div class="container" *ngIf="simplePage && isTranslationFound" >
        
        <div class="-mt-10 -mb-4 border-b border-gray-200">
          <h2 class="lg:text-center">{{ title }}</h2>
        </div>
      
        <div class="flex flex-wrap overflow-hidden">
      
          <div class="flex flex-col items-center justify-center w-full overflow-hidden">
            <div class="content prose prose-blue lg:prose-lg mt-5" *ngIf="markdownContent" >
      
              <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>
            
            </div>
          </div>
      
        </div>
    </div>
  
  </div>