<div class="home-page-wrapper " >
    
  <app-video-hero *ngIf="heroIsVisible" [title]="heroTitle" [youtubeId]="heroYoutubeId"></app-video-hero>
    
  <app-about-app-header></app-about-app-header>
  
  <!-- TEMP hidden -->
  <div *ngIf="false" class="container flex flex-col justify-center items-center" >

    <cms-content-status [status]="status"></cms-content-status>

    <div class="prose lg:prose-lg prose-blue" >

      <div class="border-b border-gray-200">
        <h2>{{ title }}</h2>
      </div>

      <div class="flex flex-wrap overflow-hidden pb-2">

        <div class="w-full overflow-hidden">
          <div class="content" >
            <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>
          </div>
        </div>

      </div>
    </div>

  </div>
  
  <app-project-partners></app-project-partners>
</div>



