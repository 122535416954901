import { Injectable } from '@angular/core';
import { FLNavigation } from 'ng-flamelink';
import { from } from 'rxjs';
import { Lang, LanguageKey } from '../../models/language.model';
import { Navigation } from '../../models/navigation.model';
import { HelpersService } from '../helpers/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private navigation: FLNavigation,
    private helpers: HelpersService,
  ) { }

  /**
   * Navigation usually in the header
   * @param languageKey
   * @returns
   */
  public async getPrimaryNavigation(languageKey: LanguageKey) {
    if (languageKey === Lang.hr) {
      return this.getNavigation('primarnaNavigacija');
    }
    return this.getNavigation('primaryNavigation');
  }

  /**
   * Navigation usually in the footer
   * @param languageKey
   * @returns
   */
  public async getSecondaryNavigation(languageKey: LanguageKey) {
    if (languageKey === Lang.hr) {
      return this.getNavigation('sekundarnaNavigacija');
    }
    return this.getNavigation('secondaryNavigation');
  }

  private async getNavigation(key: string): Promise<Navigation> {

    const navigation$ = this.helpers.safeUseScullyTransferState(`navigation_${key}`, from(this.getNavigationDocument(key)));

    return new Promise((resolve) => {
      const sub = navigation$.subscribe((navigation: Navigation) => {
        // console.log('getNavigation', key, navigation);

        // NOTE: take one value and unsubscribe
        setTimeout(() => {
          sub.unsubscribe();
        });

        if (!navigation) {
          console.error(`Navigation with key '${key}' is not found at Flamelink CMS!`);
          alert(`Please add '${key}' in Flamelink CMS.`);
        }

        const data: Navigation = {
          id: navigation.id,
          title: navigation.title,
          items: []
        };

        // Keep only first level of the navigation tree
        for (let item of navigation.items) {
          data.items.push({
            title: item.title,
            link: (item as any).url,
          });
        }

        resolve(data);
      });
    });

  }

  private getNavigationDocument<T>(key: string): Promise<T> {
    /**
     * https://flamelink.github.io/flamelink/#/navigation?id=get
     */
    return this.navigation.get({
      navigationKey: key,
      structure: 'tree',
    })
  }
}
