import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { GlobalTranslation } from '../../models/globals.model';
import { Lang, LanguageKey } from '../../models/language.model';
import { NavigationService } from '../../services/navigation/navigation.service';
import { GlobalsQuery } from '../../state/globals/globals.query';
import { GlobalsService } from '../../state/globals/globals.service';
import { LanguageQuery } from '../../state/language/language.query';

@UntilDestroy()
@Component({
  selector: 'sss-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  firstLine: string
  lastLine: string
  addressLink: string
  addressText: string
  copyright: string
  appStoreLink: string
  googlePlayLink: string
  facebookLink: string
  instagramLink: string

  navigationItems: { title: string, link: string, isActive: boolean }[]

  navigationItemsLeft: { title: string, link: string, isActive: boolean }[]
  navigationItemsRight: { title: string, link: string, isActive: boolean }[]

  constructor(
    private globalsService: GlobalsService,
    private navigation: NavigationService,
    private ims: IdleMonitorService,
    private languageQuery: LanguageQuery,
  ) { }

  ngOnInit(): void {

    // Listen for active language
    this.languageQuery.select().pipe(untilDestroyed(this)).subscribe(language => {
      this.fetchNavigationItems(language.key)
    })

    // Listen for global translations change
    this.globalsService.currentTranslation$.pipe(untilDestroyed(this)).subscribe((globalTranslation: GlobalTranslation) => {
      console.log('footer.globalTranslation', globalTranslation)
      if (globalTranslation) {
          this.firstLine = globalTranslation.footer.firstLine
          this.lastLine = globalTranslation.footer.lastLine
          this.addressText = globalTranslation.address.text
          this.addressLink = globalTranslation.address.link
          this.copyright = globalTranslation.copyright.text
          this.appStoreLink = globalTranslation.application.appStore.link
          this.googlePlayLink = globalTranslation.application.googlePlay.link
          this.facebookLink = globalTranslation.social.facebook.link
          this.instagramLink = globalTranslation.social.instagram.link
      }
    })

  }

  /**
   * Fetch navigation items by language.key
   * @param languageKey
   */
   private async fetchNavigationItems(languageKey: LanguageKey) {

    const secondaryNavigation = await this.navigation.getSecondaryNavigation(languageKey);
    console.log('FooterComponent.secondaryNavigation', secondaryNavigation, 'language.key', languageKey);

    // Cache to Scully transfer state
    // TODO(stepanic): refactor to some better place
    if (languageKey === Lang.en) {
      await this.navigation.getSecondaryNavigation(Lang.hr)
    }
    if (languageKey === Lang.hr) {
      await this.navigation.getSecondaryNavigation(Lang.en)
    }

    this.navigationItems = secondaryNavigation.items as any;

    this.separateLeftAndRightNavigationItems()

    console.log('footer.fireManualMyAppReadyEvent');
    // this.ims.fireManualMyAppReadyEvent();
  }

  /**
   * Separate navigation items to left and right columns
   * @param navigationItems
   */
  private separateLeftAndRightNavigationItems(): void {

    const middleIdx = Math.ceil(this.navigationItems.length / 2)
    this.navigationItemsLeft = this.navigationItems.slice(0, middleIdx)
    this.navigationItemsRight = this.navigationItems.slice(middleIdx)

    // console.log('FooterComponent.navigationItemsLeft', this.navigationItemsLeft)
    // console.log('FooterComponent.navigationItemsRight', this.navigationItemsRight)
  }


}
