<div class="about-app-wrapper">
      <section>
        <div class="flex flex-wrap bg-white">
          <div class="mx-auto text-center h-full">
            <h2 class="my-10 text-2xl lg:text-4xl font-bold">Kako aplikacija radi</h2>
          </div>
        </div>
      </section>
      <section>
        <div class="py-10 bg-gray-50">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap">
              <div class="md:mb-0 w-full md:w-1/2"><img src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/about-page/mobile-phone-smartlist.svg" alt=""></div>
              <div class="w-full md:w-1/2 md:text-left text-center">
                <div class="max-w-md"><span class="text-color-primary font-bold">Smartlist</span>
                  <h2 class="md:mb-6 mb-2 md:mt-4 text-2xl lg:text-4xl font-bold font-heading">Pametna shopping lista za kupovinu u jednom ili više dućana</h2>
                  <p class="text-gray-500 leading-loose">Jednostavno dodaj proizvode na listu, saznaj informacije o proizvodu ili pošalji listu nekome da obavi šoping za tebe! Aplikacija čak i razmišlja za tebe i može te podsjetiti da li si zaboravio kupiti kruh ili da li ti nedostaje šećera?</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="py-10">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap">
              <div class="lg:mb-0 w-full md:w-1/2 order-last md:order-1 md:text-right text-center">
                <div class="max-w-md md:pr-10"><span class="text-color-primary font-bold">Scan & GO</span>
                  <h2 class="md:mb-6 mb-2 md:mt-4 text-2xl lg:text-4xl font-bold font-heading">Čekanje u redovima na blagajni je minimizirano</h2>
                  <p class="text-gray-500 leading-loose">Kupnju obavi jednostavnije nego ikad uz ScanShop digitalnog asistenta. Skeniraj proizvod kad ga stavljaš u košaricu i na izlazu iz trgovine plati skenirano te preskoči čekanje u redu. Artikle ne moraš ni vaditi iz kolica nego ih tijekom kupnje stavljaš direktno u vrećice!</p>
                </div>
              </div>
              <div class="w-full md:w-1/2 order-1 md:order-last"><img src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/about-page/mobile-phone-scan-and-go.svg" alt=""></div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <section>
          <div class="py-10 bg-gray-50">
            <div class="container mx-auto px-4">
              <div class="flex flex-wrap">
                <div class="md:mb-0 w-full md:w-1/2"><img src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/about-page/mobile-phone-receipt.svg" alt=""></div>
                <div class="w-full md:w-1/2 md:text-left text-center">
                  <div class="max-w-md"><span class="text-color-primary font-bold">Digitalni račun i plaćanje troškova</span>
                    <h2 class="md:mb-6 mb-2 md:mt-4 text-2xl lg:text-4xl font-bold font-heading">Povijest svih kupovina na jednom mjestu</h2>
                    <p class="text-gray-500 leading-loose">Za svaku kupnju račun ti je zauvijek dostupan kroz aplikaciju bez potrošnje papira! Nema više čuvanja računa od garancije ili traženje nekog starog računa, a svi troškovi se sortiraju pa na kraju mjeseca možeš analizirati sve svoje troškove.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="py-10">
            <div class="container mx-auto px-4">
              <div class="flex flex-wrap">
                <div class="lg:mb-0 w-full md:w-1/2 order-last md:order-1 md:text-right text-center">
                  <div class="max-w-md md:pr-10"><span class="text-color-primary font-bold">Informacije o proizvodima, preporuke i upozorenja</span>
                    <h2 class="md:mb-6 mb-2 md:mt-4 text-2xl lg:text-4xl font-bold font-heading">Transparentnost informacija</h2>
                    <p class="text-gray-500 leading-loose">Na dohvat prsta imaš sve informacije o proizvodima prikazane na ugodan i čitak način na tvom telefonu. Zaboravi na čitanje sitnih slova i iskoristi mogućnost da te aplikacija sama upozori na neželjeni sastojak u nekom proizvodu, lagano saznaj koji proizvod je bez glutena ili iz eko uzgoja.</p>
                  </div>
                </div>
                <div class="w-full md:w-1/2 order-1 md:order-last"><img src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/about-page/mobile-phone-product-info.svg" alt=""></div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="py-10 bg-gray-50">
            <div class="container mx-auto px-4">
              <div class="flex flex-wrap">
                <div class="md:mb-0 w-full md:w-1/2"><img src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/about-page/mobile-phone-discount.svg" alt=""></div>
                <div class="w-full md:w-1/2 md:text-left text-center">
                  <div class="max-w-md"><span class="text-color-primary font-bold">Uhvati akciju koja postoji samo za tebe</span>
                    <h2 class="md:mb-6 mb-2 md:mt-4 text-2xl lg:text-4xl font-bold font-heading">Personalizirani popusti</h2>
                    <p class="text-gray-500 leading-loose">Uz ScanShop aplikaciju ostvari dodatne popuste koji su dostupni samo za tebe kroz aplikaciju. Uvijek lagano provjeri cijenu bilo kojeg proizvoda, ostvareni dodatne uštede baš na artikle koji ti trebaju!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>  
</div>
