import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GlobalTranslation } from '../../models/globals.model';
import { GlobalsService } from '../../state/globals/globals.service';

@UntilDestroy()
@Component({
  selector: 'app-video-hero',
  templateUrl: './video-hero.component.html',
  styleUrls: ['./video-hero.component.scss']
})
export class VideoHeroComponent implements OnInit {

  @Input()
  title: string
  @Input()
  youtubeId: string
  
  youtubeEmbedSafeUrl: any
  
  isVideoPreloading = true
  
  appStoreLink: string
  googlePlayLink: string  
    
  constructor(
    private globalsService: GlobalsService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
      
    // 3.6 seconds is enough that YouTube player hides controls after autoplay
    setTimeout(() => {
      this.isVideoPreloading = false
    }, 3600)
    
    // Listen for global translations change
    this.globalsService.currentTranslation$.pipe(untilDestroyed(this)).subscribe((globalTranslation: GlobalTranslation) => {
      console.log('video-hero.globalTranslation', globalTranslation)
      this.appStoreLink = globalTranslation.application.appStore.link
      this.googlePlayLink = globalTranslation.application.googlePlay.link              
    })
  }
  
  ngOnChanges(changes: SimpleChanges) {
    // console.log('VideoHeroComponent.changes', changes);
    const youtubeIdChange = changes.youtubeId
    if (youtubeIdChange) {
      if (youtubeIdChange.currentValue !== youtubeIdChange.previousValue) {
        this.initYoutubeEmbedSafeUrl()
      }
    }
  }
  
  private initYoutubeEmbedSafeUrl() {
    this.youtubeEmbedSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/embed/${this.youtubeId}?controls=0&autoplay=1&mute=1&playlist=${this.youtubeId}&loop=1&modestbranding=1&rel=0'`
    )
  }
  

}
