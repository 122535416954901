import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FLNavigationModule } from 'ng-flamelink';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ButtonComponent } from './button/button.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LayoutComponent } from './layout/layout.component';
import { VideoHeroComponent } from './video-hero/video-hero.component';
import { ProjectPartnersComponent } from './project-partners/project-partners.component';
import { AboutAppComponent } from './about-app/about-app.component';
import { AboutAppHeaderComponent } from './about-app-header/about-app-header.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FLNavigationModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    NavbarComponent,
    LayoutComponent,
    VideoHeroComponent,
    ProjectPartnersComponent,
    AboutAppComponent,
    AboutAppHeaderComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    NavbarComponent,
    LayoutComponent,
    VideoHeroComponent,
    ProjectPartnersComponent,
    AboutAppComponent,
    AboutAppHeaderComponent
  ],
})
export class UiModule {}
