import { Component, OnInit } from '@angular/core';
import { LanguageQuery } from '../../state/language/language.query';
import { LanguageState } from '../../state/language/language.store';

@Component({
  selector: 'app-project-partners',
  templateUrl: './project-partners.component.html',
  styleUrls: ['./project-partners.component.scss']
})
export class ProjectPartnersComponent implements OnInit {

  language: LanguageState;  
    
  constructor(
    private languageQuery: LanguageQuery,
  ) { }

  ngOnInit(): void {
      
    this.languageQuery.select().subscribe(language => {
        this.language = language;
    })
  }

}
