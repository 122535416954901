import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { COMPLEMENT_ROUTES_DEFINITION } from '../../app-routing.module';
import { Lang, Language, LanguageKey } from '../../models/language.model';
import { NavigationService } from '../../services/navigation/navigation.service';
import { GlobalsService } from '../../state/globals/globals.service';
import { LanguageQuery } from '../../state/language/language.query'
import { LanguageService } from '../../state/language/language.service'

@UntilDestroy()
@Component({
  selector: 'sss-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input()
  titleImage: string;

  @Input()
  title: string;

  // Split title by ` ` space and first word is titleStart and titleEnd are other words
  titleStart: string;
  titleEnd: string;

  @Input()
  titleLink: string;

  @Input()
  mainImage: string;

  @Input()
  mainImageLink = '#';

  @Input()
  copyrightText: string;

  /**
   * Has priority against items[index].isActive === false
   */
  @Input()
  activeLink: string;

  @Input()
  items: { title: string, link: string, isActive: boolean }[]

  @Input()
  isOpenMenu = false;

  public currentLanguageKey: LanguageKey

  /**
   * Set it to true to list all available languages
   */
  public isMultiLanguage = false
  public availableLanguages: Language[] = [
    {
      key: Lang.en,
      title: 'english',
    },
    {
      key: Lang.hr,
      title: 'hrvatski'
    }
  ]

  private currentRouterUrl: string
  private isLastActionLanguageSet = false

  constructor(
    private router: Router,
    private languageQuery: LanguageQuery,
    private languageService: LanguageService,
    private navigation: NavigationService,
    private globalsService: GlobalsService,
  ) {

  }

  async ngOnInit(): Promise<void> {

    this.markActiveItem();
    this.splitTitle();

    // Listen for active language
    this.languageQuery.select().pipe(untilDestroyed(this)).subscribe(async (language) => {
      console.log('navbar.component.constructor.select.query.language', language, 'currentRouterUrl', this.currentRouterUrl)
      this.currentLanguageKey = language.key

      // TODO(stepanic): refactor this hack
      // Special handing for titleLink
      if (this.currentLanguageKey === 'hr') {
        this.titleLink = '/naslovnica'
      } else {
        // Fallback to english
        this.titleLink = '/home'
      }

      // Navigate to complement route...
      const complementRoute = this.getComplementRoute(this.currentRouterUrl)
      // ...if found
      if (complementRoute || complementRoute === '') {
        // NOTE(stepanic): much better UX is to navigate natively in the browser instead of navigation with Angular router
        this.router.navigateByUrl('/' + complementRoute)
        // location.href = '/' + complementRoute

        // NOTE(stepanic): best UX is to replace URL directly with history API and handle language chnage in the component
        // But this is bad because browser back does not work :(
        // history.replaceState(null, null, '/' + complementRoute)
      } else {
        // When it is selected route from another language than correct language should be set
        if (!this.currentRouterUrl) {
          const correctLanguage = this.getLanguageKeyByRoute(location.pathname)
          if (correctLanguage) {
            if (correctLanguage !== this.currentLanguageKey) {
              this.currentRouterUrl = location.pathname
              this.currentLanguageKey = correctLanguage
            }
          }
        }
      }

      await this.fetchNavigationItems(this.currentLanguageKey)
      
    })

    // Listen for URL changes at router
    this.router.events
      .pipe(untilDestroyed(this))
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {

        // const prevUrl = this.currentRouterUrl
        const nextUrl = event.url
        this.currentRouterUrl = nextUrl

        // console.log('navbar.router.event', 'from', prevUrl, 'to', nextUrl)

        // This is required for navigating to another route without language chnage
        if (!this.isLastActionLanguageSet) {
          this.markActiveItem();
        }

    })
    
    // Check Global settings and make multi language available if enabled mor than 1 language
    const globalsCurrent = this.globalsService.current
    console.log('navbar.component.constructor.globals.current', globalsCurrent)
    if (globalsCurrent) {
        if (globalsCurrent?.en?.isEnabled && globalsCurrent?.hr?.isEnabled) {
            this.isMultiLanguage = true
        } else {
            this.isMultiLanguage = false
        }      
    }
  }

  /**
   * Set current language by language key
   * @param languageKey
   */
  public setLanguage(languageKey) {
    this.isLastActionLanguageSet = true
    this.languageService.current = languageKey
  }

  /**
   * Try to find complement route between languages
   * @param currentRouterUrl
   * @returns
   */
  private getComplementRoute(currentRouterUrl: string) {
    if (currentRouterUrl) {

      const needle = currentRouterUrl.length > 0 ? currentRouterUrl.substr(1) : currentRouterUrl // '' route is a specific case

      for (const lang of Object.keys(COMPLEMENT_ROUTES_DEFINITION)) {
        if (lang === this.currentLanguageKey) {
          continue
        }
        const routes = Object.keys(COMPLEMENT_ROUTES_DEFINITION[lang])
        for (const route of routes) {
          if (needle === '' && COMPLEMENT_ROUTES_DEFINITION[lang] && COMPLEMENT_ROUTES_DEFINITION[lang][needle]) {
            return COMPLEMENT_ROUTES_DEFINITION[lang][needle][this.currentLanguageKey]
          } else {
            if (needle.startsWith(route) && COMPLEMENT_ROUTES_DEFINITION[lang] && COMPLEMENT_ROUTES_DEFINITION[lang][needle]) {
              return COMPLEMENT_ROUTES_DEFINITION[lang][needle][this.currentLanguageKey]
            }
          }
        }
      }

    }

    return null
  }

  /**
   * Get language key from URL route
   * @param route
   */
  private getLanguageKeyByRoute(route: string): LanguageKey {

    const needle = route.length > 0 ? route.substr(1) : route // '' route is a specific case

    for (const lang of Object.keys(COMPLEMENT_ROUTES_DEFINITION)) {
      if (COMPLEMENT_ROUTES_DEFINITION[lang][needle]) {
        return lang as LanguageKey
      }
    }

    return null
  }

  /**
   * Fetch navigation items by language.key
   * @param languageKey
   */
   private async fetchNavigationItems(languageKey: LanguageKey) {

    const primaryNavigation = await this.navigation.getPrimaryNavigation(languageKey);
    // console.log('AppComponent.primaryNavigation', primaryNavigation, 'language.key', languageKey);

    // Cache to Scully transfer state
    // TODO(stepanic): refactor to some better place
    if (languageKey === Lang.en) {
      await this.navigation.getPrimaryNavigation(Lang.hr)
    }
    if (languageKey === Lang.hr) {
      await this.navigation.getPrimaryNavigation(Lang.en)
    }

    this.items = primaryNavigation.items as any;

    // To avoid flash !at UX it it required to mark active here
    this.markActiveItem()
    // Reset state
    this.isLastActionLanguageSet = false
  }

  /**
   * Check location.pathname and make active correct navbar item
   */
  private markActiveItem(): void {

    // Special handling for root link
    if (location.pathname === '/') {
        if (this.currentLanguageKey === 'hr') {
            this.activeLink = '/naslovnica'
        } else {
            this.activeLink = '/home'
        }
        return
    }
    
    if (location.pathname.indexOf('/blog.hr/eu-projekt-2019-2021') === 0) {
        this.activeLink = '/blog.hr/eu-projekt-2019-2021'
        return
    }
    
    if (location.pathname.indexOf('/blog/eu-project-2019-2021') === 0) {
        this.activeLink = '/blog/eu-project-2019-2021'
        return
    }
    
    if (location.pathname !== this.activeLink) {
      this.activeLink = location.pathname;

      const parts = this.activeLink?.split('/');
      if (parts?.length >= 2) {
        if (parts[1] === Lang.hr || parts[1] === Lang.en) {
          this.activeLink = '/' + parts[1] + '/' + parts[2]
        } else {
          this.activeLink = '/' + parts[1];
        }
      }

      // Do not close manu between language change
      if (this.isLastActionLanguageSet) {
        setTimeout(() => {
          this.isLastActionLanguageSet = false
        })
      } else {
        this.isOpenMenu = false;
      }
    }
  }

  private splitTitle(): void {
    const parts = this.title.split(' ');
    this.titleStart = parts[0];
    delete parts[0];
    this.titleEnd = parts.join(' ');
  }

}
