import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { LanguageKey } from '../../models/language.model';
import { SimplePage } from '../../models/simple.page.model';
import { PagesContentService } from '../../services/pages/pages-content.service';
import { LanguageQuery } from '../../state/language/language.query';
import { LanguageService } from '../../state/language/language.service';

@UntilDestroy()
@Component({
  selector: 'app-simple-page',
  templateUrl: './simple-page.component.html',
  styleUrls: ['./simple-page.component.scss']
})
export class SimplePageComponent implements OnInit {

  public simplePage: SimplePage;
  
  public currentLanguageKey: string;
  public isTranslationFound = true

  public status: string;
  public title: string;
  public markdownContent: string;
  
  private currentSlug: string;
    
  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private ims: IdleMonitorService,
      private content: PagesContentService,
      private languageService: LanguageService,
      private languageQuery: LanguageQuery,
  ) { 
      this.route.params.pipe(untilDestroyed(this)).subscribe(async (params) => {
          console.log('SimplePageComponent.params', params);
          
          const slug = params?.slug
          
          if (slug) {
            this.currentSlug = slug;
            this.fetchData(slug)
          } 
      });
  }

  ngOnInit(): void {
    
    // On language change just navigate to the same page but to compatible route in another language
    this.languageQuery.select().pipe(untilDestroyed(this)).subscribe(async (language) => {

      if (!language) {
        return  
      }
      
      this.currentLanguageKey = language.key;
      
      if (this.simplePage) {
        if (this.simplePage.language !== this.currentLanguageKey) {
          if (this.simplePage[this.currentLanguageKey]) {
            if (this.simplePage[this.currentLanguageKey].translation.slug) {
              this.router.navigate(['/' + this.simplePage[this.currentLanguageKey].translation.slug]);
            } else {
              this.isTranslationFound = false;
              
              this.status = undefined;
              this.title = undefined;
              this.markdownContent = undefined;
            }
          }
        } else {
          this.isTranslationFound = true;
          
          this.status = this.simplePage.status;
          this.title = this.simplePage.title;
          this.markdownContent = this.simplePage.content;
        }  
      }     
      
    })
    
  }  
  
  private async fetchData(slug: string): Promise<void> {
    const simplePage = await this.content.getSimplePage(slug) 
    
    if (simplePage) {
      
      this.simplePage = simplePage;
      
      this.status = simplePage.status;
      this.title = simplePage.title;
      this.markdownContent = simplePage.content;
      
      this.isTranslationFound = true;
      
      if (simplePage.language) {
        if (this.currentLanguageKey !== simplePage.language) {
          this.languageService.current = simplePage.language;
        }
      }
    } else {
      this.simplePage = null;
    }
  }
  
  private isMarkdownContentReadyCounter = 0;

  public onReadyMarkdownContent(): void {
    console.log('simplePage.onReadyMarkdownContent');
    if (this.simplePage) {
      this.isMarkdownContentReadyCounter += 1;
      // Fire that app is ready when all markdown fields are rendered
      if (this.isMarkdownContentReadyCounter >= 1) {
        console.log('simplePage.fireManualMyAppReadyEvent');
        // this.ims.fireManualMyAppReadyEvent();
      }
    }
  }  

}
