<div class="footer-wrapper">

  <footer class="">

    <section>
      <div class="py-20 bg-primary text-white">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="w-full lg:w-1/3 mb-16 lg:mb-0">
              <a class="inline-block mb-3 text-3xl font-bold leading-none" [routerLink]="[navigationItemsLeft?.length && navigationItemsLeft[0]?.link]">
                <img class="mx-auto" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/scanshop-logo-white-with-title-white.svg">
              </a>
              <p class="mb-0 max-w-sm leading-loose" [innerHTML]="firstLine"></p>
              <p class="mb-4 max-w-sm leading-loose" [innerHTML]="lastLine"></p>
              <div>
                <div *ngIf="!facebookLink?.length" class="inline-block w-10 mr-2 p-2 opacity-50" [href]="facebookLink">
                  <img class="mx-auto" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/social-icons/facebook-circle-white.svg">
                </div>
                <div *ngIf="!instagramLink?.length" class="inline-block w-10 mr-2 p-2 opacity-50" [href]="instagramLink">
                  <img class="mx-auto" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/social-icons/instagram-circle-white.svg">
                </div>

                <a *ngIf="facebookLink?.length" class="inline-block w-10 mr-2 p-2" [href]="facebookLink" target="_blank">
                  <img class="mx-auto" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/social-icons/facebook-circle-white.svg">
                </a>
                <a *ngIf="instagramLink?.length" class="inline-block w-10 mr-2 p-2" [href]="instagramLink" target="_blank">
                  <img class="mx-auto" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/social-icons/instagram-circle-white.svg">
                </a>
              </div>
            </div>
            <div class="w-full lg:w-2/3 lg:pl-16 flex flex-wrap justify-between">
              <div class="w-1/2 md:w-1/3 lg:w-auto mb-16 md:mb-0">
                <ul>
                  <li class="mb-4" *ngFor="let item of navigationItemsLeft"><a class="hover:text-gray-700" [routerLink]="item.link">{{ item.title }}</a></li>
                </ul></div>
              <div class="w-1/2 md:w-1/3 lg:w-auto mb-16 md:mb-0">
                <ul>
                  <li class="mb-4" *ngFor="let item of navigationItemsRight"><a class="hover:text-gray-700" [routerLink]="item.link">{{ item.title }}</a></li>
                </ul></div>
              <div class="w-full md:w-1/3 lg:w-auto text-center sm:text-right">
                <ul class="flex items-end flex-col">
                  <li class="mb-4">
                    <a class="hover:text-gray-700 address-link" [href]="addressLink" target="_blank" [innerHTML]="addressText"></a>
                  </li>
                  <li class="mb-4 flex flex-col">

                    <div class="inline-block w-52 mt-6 pl-2" *ngIf="!appStoreLink?.length">
                      <img class="mx-auto w-full opacity-50" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/get-on-store-icons/app-store-en.svg?updatedAt=1633701866762">
                    </div>

                    <div class="inline-block w-52 mt-6 pl-2" *ngIf="!googlePlayLink?.length">
                      <img class="mx-auto w-full opacity-50" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/get-on-store-icons/google-play-en.svg?updatedAt=1633701855731">
                    </div>

                    <a class="inline-block w-52 mt-6 pl-2" [href]="appStoreLink" target="_blank" *ngIf="appStoreLink?.length">
                      <img class="mx-auto w-full" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/get-on-store-icons/app-store-en.svg?updatedAt=1633701866762">
                    </a>
                    <a class="inline-block w-52 mt-6 pl-2" [href]="googlePlayLink" target="_blank" *ngIf="googlePlayLink?.length">
                      <img class="mx-auto w-full" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/get-on-store-icons/google-play-en.svg?updatedAt=1633701855731">
                    </a>
                  </li>
                </ul></div>
            </div>
          </div>
        </div>
      </div>
      <p class="lg:text-center text-sm text-gray-700 p-12" [innerHTML]="copyright"></p>
    </section>

  </footer>

</div>


