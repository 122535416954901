import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { isScullyGenerated, ScullyLibModule } from '@scullyio/ng-lib';
import { FlamelinkModule, FLContentModule, FLNavigationModule, FLStorageModule } from 'ng-flamelink';
import { environment } from '../environments/environment';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { SharedModule } from './shared/shared.module';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { MarkdownModule } from 'ngx-markdown';
import { SecurityContext } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { GalleryModule, GALLERY_CONFIG } from 'ng-gallery';
import { LightboxModule, LIGHTBOX_CONFIG } from 'ng-gallery/lightbox';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { UiModule } from './ui/ui.module';
import { SimplePageComponent } from './pages/simple-page/simple-page.component';

const imports = [
  [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ScullyLibModule.forRoot({
      useTransferState: true,
      alwaysMonitor: false,
      manualIdle: true,
    }),

    GalleryModule,
    LightboxModule,

    FlamelinkModule.initialize({
      dbType: 'cf', // Cloud Firestore or 'rtdb' for Real-Time Database
      firebaseApp: environment.firebaseConfig
    }),
    FLContentModule,
    FLStorageModule,
    FLNavigationModule,
    // NOTE: inside of FlamelinkModule.initialize() will be initialized Firebase app, and it is not required to init twice
    //AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,

    MarkdownModule.forRoot({
      // NOTE: sanitize by default is better, uncomment this only if you know what are you doing :))
      // simple HTML <b><div>, etc. are supported inside of the Markdown by default with sanitizer, but e.g. <script> is not!
      // sanitize: SecurityContext.NONE
    }),

    UiModule,

    environment.production ? [] : AkitaNgDevtools.forRoot(),

    AkitaNgRouterStoreModule,

  ]
]

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AboutPageComponent,
    ContactPageComponent,
    SimplePageComponent
  ],
  imports,
  providers: [
    {
      provide: GALLERY_CONFIG,
      useValue: {
        dots: true,
        imageSize: 'contain',
        // imageSize: 'cover',
      }
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: true,
        panelClass: 'fullscreen',
        hasBackdrop: true,
      }
    },
    // { provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' }}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
