
<div class="video-hero-wrapper z-10 max-w-max overflow-hidden" >
    <div class="video-container">
        <iframe [src]="youtubeEmbedSafeUrl"></iframe>        
        <div class="video-img" [class.video-img-hidden]="!isVideoPreloading" [style.background-image]="'url(https://img.youtube.com/vi/' + youtubeId + '/maxres2.jpg)'" ></div>
    </div>   
    
    <!-- Nice way to disable control of the video but on the mobile autoplay is not possible so user should have ability for play/pause the video -->
    <!-- <div class="video-invisible-overlay"></div> -->

    <div class="video-overlay-text">
        <section class="pb-16">
            <div class="flex flex-wrap">
                <div class="w-screen lg:pl-32 lg:pt-128 pt-16 text-white z-30">
                    <div class="lg:max-w-2xl lg:text-left text-center">
                        <div>
                            <h2 class="text-3xl lg:text-5xl px-4 lg:px-0 font-bold font-heading" style="line-height: 1.25;" [innerHtml]="title"></h2>
                        </div>
                      
                        <div class="pt-8 lg:pt-1">
                            
                            <div class="inline-block" *ngIf="!appStoreLink?.length">
                                <img class="mx-auto mt-2 lg:mt-6 pr-1 h-10 lg:h-16 opacity-50" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/get-on-store-icons/app-store-en.svg?updatedAt=1633701866762">
                            </div>
          
                            <div class="inline-block" *ngIf="!googlePlayLink?.length">
                                <img class="mx-auto mt-2 lg:mt-6 pl-1 h-10 lg:h-16 opacity-50" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/get-on-store-icons/google-play-en.svg?updatedAt=1633701855731">
                              </div>
          
                            
                            <a [href]="appStoreLink" target="_blank" *ngIf="appStoreLink?.length">
                                <img class="inline-block pr-1 h-10 lg:h-16 mt-2 lg:mt-6" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/get-on-store-icons/app-store-en.svg?updatedAt=1633701866762" alt="Download on the App Store">
                            </a>
                            <a [href]="googlePlayLink" target="_blank" *ngIf="googlePlayLink?.length">
                                <img class="inline-block pl-1 h-10 lg:h-16 mt-2 lg:mt-6" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/get-on-store-icons/google-play-en.svg?updatedAt=1633701855731" alt="Get it on Google Play">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    
    

</div>
